:root {
  --abhaya-libre: 'Abhaya Libre', serif;

  --background-color: white;
  --font-color: #262626;
  --primary-color: #129D42;
  --navigation-background: white;
  --navigation-border: #d1d1d1;
  --link-color: #2B4DFF;
}

[data-theme='dark'] {
  --background-color: #262626;
  --font-color: white;
  --primary-color: #262626;
  --navigation-background: #1D1D1D;
  --navigation-border: transparent;
  --link-color: white;
}
