.App {
  text-align: center;
  // background-color: #282c34;
  background-color: var(--background-color);
  height: 100vh;
  color: var(--font-color);
  
  a {
    color: var(--link-color);
  }
}
