.__entry-editor {
  background-color: var(--background-color);
  min-height: 100vh;

  .top-navigation {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    background-color: var(--navigation-background);
    color: var(--font-color);
    align-items: center;
    transition: 0.5s;
    z-index: 100;
    border-bottom: 1px solid var(--navigation-border);

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    .date {
      line-height: 24px;
    }

    .word-count {
      font-size: 24px;
    }
  }

  .editor {
    padding-top: 92px;
    margin: 0 auto;
    width: 80%;
    height: 100%;
    max-width: 780px;

    @media screen and (max-width: 480px) {
      padding-top: 80px;
      width: 100%;
    }
  }

  .editor-container {
    position: relative;
    height: 100%;
  }

  .entry-text {
    padding: 12px 24px 60px;
    font-family: var(--abhaya-libre);
    font-size: 24px;
    line-height: 38px;

    @media screen and (max-width: 480px) {
      font-size: 20px;
      line-height: 32px;
    }
  }

  textarea.entry {
    color: var(--font-color);
    resize: none;
    background-color: var(--background-color);
    border: none;
    height: 100%;
    width: 100%;

    overflow: hidden;
  
    &:focus {
      outline: none;
    }
  }

  .entry-height {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    
    color: red;
    white-space: pre-wrap;
    visibility: hidden;
    // allows to see the entry div
    // opacity: 0.5;
  }

  button.save-and-close {
    background-color: #C02626;
    color: white;
    border: 2px solid rgba(white, 0.7);
    border-radius: 8px;
    font-family: var(--abhaya-libre);
    box-shadow: 0px 2px 5px 1px rgba(black, 0.1);
    font-size: 21px;
    padding: 6px 28px;
    cursor: pointer;
    min-width: 172px;
    transition: 0.3s;
    user-select: none;

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .toggle-fullscreen {
    position: fixed;
    bottom: 32px;
    right: 20px;
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    border-radius: 32px;
    border: 1px solid transparent;
    padding: 4px 16px;
    color: white;
    gap: 12px;
    opacity: 0.5;
    transition: 0.5s;

    @media screen and (max-width: 480px) {
      display: none;
    }

    > svg {
      fill: white;
    }

    .text {
      max-width: 0;
      height: 0;
      overflow: hidden;
      white-space: nowrap;
      font-family: var(--abhaya-libre);
      opacity: 0;
      font-size: 18px;
      transition: 0.5s;
    }

    &:hover {
      border-color: #888888;
      background-color: #3b3b3b;
      opacity: 1;
      transition: 0.5s;

      .text {
        max-width: 300px;
        height: auto;
        transition: 1s;
        opacity: 1;
      }
    }

    &.hidden {
      opacity: 0;
      transition: 0.3s;
      pointer-events: none;
    }
  }
}
